import React, { useState } from "react";
import { SizeModal } from "../../mollecules";
import { OpelModal, SizesList } from "../../organisms";

function SizesTemplate() {
  const [toggleSizeModal, setToggleSizeModal] = useState({
    active: false,
    category: null,
  });

  const openSizeModal = () => {
    setToggleSizeModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeSizeModal = () => {
    setToggleSizeModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editSizeModal = (id) => {
    setToggleSizeModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleSizeModal.active && (
        <SizeModal
          closeModal={closeSizeModal}
          toggleModal={toggleSizeModal}
        />
      )}
      <OpelModal title="Ajouter un size" openModal={openSizeModal} />
      <SizesList editModal={editSizeModal} />
    </div>
  );
}

export default SizesTemplate;
