import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Switch } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addPiece,
  getPiece,
  updatePiece,
} from "../../../redux/actions/pieceActions";
import "./styles.css";

function PieceModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const {t} = useTranslation()
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (toggleModal.category) {
      getPiece(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: t(res.data.name),
            order: res.data.order,
            value: res.data.value,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddPiece = (body) => {
    if (toggleModal.category) {
      updatePiece(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addPiece(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le piece" : "Ajouter un piece"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddPiece}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du piece"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Valeur du piece"
          name="value"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <InputNumber placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PieceModal;
