import { createSlice } from "@reduxjs/toolkit";

export const pieceReducer = createSlice({
  name: "piece",
  initialState: {
    pieces: [],
    piecesLoading: false,
    pieceLoading: false,
  },
  reducers: {
    getPiecesRequest: (state) => {
      state.piecesLoading = true;
    },
    getPiecesSuccess: (state, action) => {
      state.pieces = action.payload;
      state.piecesLoading = false;
    },
    getPiecesFailed: (state) => {
      state.piecesLoading = false;
    },
    addPieceRequest: (state) => {
      state.pieceLoading = true;
    },
    addPieceSuccess: (state) => {
      state.pieceLoading = false;
    },
    addPieceFailed: (state) => {
      state.pieceLoading = false;
    },
  },
});

export const {
  getPiecesRequest,
  getPiecesSuccess,
  getPiecesFailed,
  addPieceRequest,
  addPieceSuccess,
  addPieceFailed,
} = pieceReducer.actions;

export default pieceReducer.reducer;
