import React, { useState } from "react";
import { EventModal } from "../../mollecules";
import { EventsList, OpelModal } from "../../organisms";

function EventsTemplate() {
  const [toggleEventModal, setToggleEventModal] = useState({
    active: false,
    category: null,
  });

  const openEventModal = () => {
    setToggleEventModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeEventModal = () => {
    setToggleEventModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editEventModal = (id) => {
    setToggleEventModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleEventModal.active && (
        <EventModal
          closeModal={closeEventModal}
          toggleModal={toggleEventModal}
        />
      )}
      <OpelModal title="Ajouter un event" openModal={openEventModal} />
      <EventsList editModal={editEventModal} />
    </div>
  );
}

export default EventsTemplate;
