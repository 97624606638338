import axios from "axios";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const apiUser = process.env.REACT_APP_API_USER_URL;

//test

async function loadTranslations() {
  try {
    const response = await axios.get(`${apiUser}/translations`);
    const translations = response.data;

    const resources = {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
          ...translations.en, 
        }
      },
      fr: {
        translation: {
          "Welcome to React": "Bienvenue à React et react-i18next",
          ...translations.fr, 
        }
      }
    };
    console.log(resources)

    i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: "fr", 
        interpolation: {
          escapeValue: false 
        }
      });
  } catch (error) {
    console.error("Erreur lors du chargement des traductions :", error);
  }
}

loadTranslations();

export default i18n;
