import axios from "axios";
import { toast } from "react-toastify";
import { CONDITIONS_URL } from "../api/conditionApi";
import {
  addConditionFailed,
  addConditionRequest,
  addConditionSuccess,
  getConditionsFailed,
  getConditionsRequest,
  getConditionsSuccess,
} from "../reducers/conditionReducer";
import { getTranslations } from "./translationActions";

export const getConditions = (token, dispatch) => {
  dispatch(getConditionsRequest());
  axios
    .get(CONDITIONS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getConditionsSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getConditionsFailed());
    });
};

export const getCondition = (token, id) => {
  return axios.get(`${CONDITIONS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addCondition = (token, body, closeModal, dispatch) => {
  dispatch(addConditionRequest());
  axios
    .post(CONDITIONS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addConditionSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getConditions(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addConditionFailed());
    });
};

export const updateCondition = (token, id, body, closeModal, dispatch) => {
  dispatch(addConditionRequest());
  axios
    .put(`${CONDITIONS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addConditionSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getConditions(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addConditionFailed());
    });
};
