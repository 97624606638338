import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../components/templates";
import { BlogArticles, Home, Services, Shops, Translations, Universes } from "../pages/app";
import { Login } from "../pages/auth";
import { getUser } from "../redux/actions/adminActions";
import { getBlogArticles } from "../redux/actions/blogArticleActions";
import { getCategories } from "../redux/actions/categoryActions";
import { getColors } from "../redux/actions/colorActions";
import { getConditions } from "../redux/actions/conditionActions";
import { getEvents } from "../redux/actions/eventActions";
import { getGenders } from "../redux/actions/genderActions";
import { getPieces } from "../redux/actions/pieceActions";
import { getPieceCategories } from "../redux/actions/pieceCategoryActions";
import { getPieceTypes } from "../redux/actions/pieceTypeActions";
import { getServices } from "../redux/actions/serviceActions";
import { getShops } from "../redux/actions/shopActions";
import { getSizes } from "../redux/actions/sizeActions";
import { getSubCategories } from "../redux/actions/subCategoryActions";
import { getTranslations } from "../redux/actions/translationActions";
import { getUniverses } from "../redux/actions/universeActions";

const Navigation = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.adminReducer);
  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token");
    if (tokenFromStorage) {
      const body = {
        token: tokenFromStorage,
      };
      getUser(body, dispatch);
    }
  }, [dispatch]);
  useEffect(() => {
    if (token) {
      getServices(token, dispatch);
      getBlogArticles(token, dispatch);
      getTranslations(token, dispatch);
      getShops(token, dispatch);
      getUniverses(token, dispatch);
      getCategories(token, dispatch);
      getSubCategories(token, dispatch);
      getGenders(token, dispatch);
      getConditions(token, dispatch);
      getEvents(token, dispatch);
      getPieces(token, dispatch);
      getSizes(token, dispatch);
      getColors(token, dispatch);
      getPieceCategories(token, dispatch);
      getPieceTypes(token, dispatch);
    }
  }, [dispatch, token]);
  const authRoutes = () => (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Navigate replace to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
  const adminRoutes = () => (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog" element={<BlogArticles />} />
        <Route path="/translations" element={<Translations />} />
        <Route path="/shops" element={<Shops />} />
        <Route path="/universes" element={<Universes />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
  return token ? adminRoutes() : authRoutes();
};

export default Navigation;
