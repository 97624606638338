import React, { useState } from "react";
import { CategoryModal } from "../../mollecules";
import { CategoriesList, OpelModal } from "../../organisms";

function CategoriesTemplate() {
  const [toggleCategorieModal, setToggleCategorieModal] = useState({
    active: false,
    category: null,
  });

  const openCategorieModal = () => {
    setToggleCategorieModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeCategorieModal = () => {
    setToggleCategorieModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editCategorieModal = (id) => {
    setToggleCategorieModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleCategorieModal.active && (
        <CategoryModal
          closeModal={closeCategorieModal}
          toggleModal={toggleCategorieModal}
        />
      )}
      <OpelModal title="Ajouter un categorie" openModal={openCategorieModal} />
      <CategoriesList editModal={editCategorieModal} />
    </div>
  );
}

export default CategoriesTemplate;
