import { createSlice } from "@reduxjs/toolkit";

export const pieceCategoryReducer = createSlice({
  name: "pieceCategory",
  initialState: {
    pieceCategories: [],
    pieceCategoriesLoading: false,
    pieceCategoryLoading: false,
  },
  reducers: {
    getPieceCategoriesRequest: (state) => {
      state.pieceCategoriesLoading = true;
    },
    getPieceCategoriesSuccess: (state, action) => {
      state.pieceCategories = action.payload;
      state.pieceCategoriesLoading = false;
    },
    getPieceCategoriesFailed: (state) => {
      state.pieceCategoriesLoading = false;
    },
    addPieceCategoryRequest: (state) => {
      state.pieceCategoryLoading = true;
    },
    addPieceCategorySuccess: (state) => {
      state.pieceCategoryLoading = false;
    },
    addPieceCategoryFailed: (state) => {
      state.pieceCategoryLoading = false;
    },
  },
});

export const {
  getPieceCategoriesRequest,
  getPieceCategoriesSuccess,
  getPieceCategoriesFailed,
  addPieceCategoryRequest,
  addPieceCategorySuccess,
  addPieceCategoryFailed,
} = pieceCategoryReducer.actions;

export default pieceCategoryReducer.reducer;
