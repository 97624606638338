import { createSlice } from "@reduxjs/toolkit";

export const sizeReducer = createSlice({
  name: "size",
  initialState: {
    sizes: [],
    sizesLoading: false,
    sizeLoading: false,
  },
  reducers: {
    getSizesRequest: (state) => {
      state.sizesLoading = true;
    },
    getSizesSuccess: (state, action) => {
      state.sizes = action.payload;
      state.sizesLoading = false;
    },
    getSizesFailed: (state) => {
      state.sizesLoading = false;
    },
    addSizeRequest: (state) => {
      state.sizeLoading = true;
    },
    addSizeSuccess: (state) => {
      state.sizeLoading = false;
    },
    addSizeFailed: (state) => {
      state.sizeLoading = false;
    },
  },
});

export const {
  getSizesRequest,
  getSizesSuccess,
  getSizesFailed,
  addSizeRequest,
  addSizeSuccess,
  addSizeFailed,
} = sizeReducer.actions;

export default sizeReducer.reducer;
