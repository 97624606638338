import React, { useState } from "react";
import { BlogArticleModal } from "../../mollecules";
import { BlogArticlesList, OpelModal } from "../../organisms";

function BlogArticlesTemplate() {
  const [toggleBlogArticleModal, setToggleBlogArticleModal] = useState({
    active: false,
    category: null,
  });

  const openBlogArticleModal = () => {
    setToggleBlogArticleModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeBlogArticleModal = () => {
    setToggleBlogArticleModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editBlogArticleModal = (id) => {
    setToggleBlogArticleModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleBlogArticleModal.active && (
        <BlogArticleModal
          closeModal={closeBlogArticleModal}
          toggleModal={toggleBlogArticleModal}
        />
      )}
      <OpelModal
        title="Ajouter un blogArticle"
        openModal={openBlogArticleModal}
      />
      <BlogArticlesList editModal={editBlogArticleModal} />
    </div>
  );
}

export default BlogArticlesTemplate;
