import axios from "axios";
import { toast } from "react-toastify";
import { SERVICE_IMAGE_URL, SERVICES_URL } from "../api/serviceApi";
import {
  addServiceFailed,
  addServiceRequest,
  addServiceSuccess,
  getServicesFailed,
  getServicesRequest,
  getServicesSuccess,
} from "../reducers/serviceReducer";

export const getServices = (token, dispatch) => {
  dispatch(getServicesRequest());
  axios
    .get(SERVICES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getServicesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getServicesFailed());
    });
};

export const getService = (token, id) => {
  return axios.get(`${SERVICES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addServiceImage = (token, body) => {
  return axios.post(SERVICE_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addService = (token, body, closeModal, dispatch) => {
  console.log(body);
  dispatch(addServiceRequest());
  axios
    .post(SERVICES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addServiceSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getServices(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addServiceFailed());
    });
};

export const updateService = (token, id, body, closeModal, dispatch) => {
  dispatch(addServiceRequest());
  axios
    .put(`${SERVICES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addServiceSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getServices(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addServiceFailed());
    });
};
