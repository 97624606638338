import React, { useState } from "react";
import { UniverseModal } from "../../mollecules";
import { OpelModal, UniversesList } from "../../organisms";

function UniversesTemplate() {
  const [toggleUniverseModal, setToggleUniverseModal] = useState({
    active: false,
    category: null,
  });

  const openUniverseModal = () => {
    setToggleUniverseModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeUniverseModal = () => {
    setToggleUniverseModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editUniverseModal = (id) => {
    setToggleUniverseModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleUniverseModal.active && (
        <UniverseModal
          closeModal={closeUniverseModal}
          toggleModal={toggleUniverseModal}
        />
      )}
      <OpelModal title="Ajouter un universe" openModal={openUniverseModal} />
      <UniversesList editModal={editUniverseModal} />
    </div>
  );
}

export default UniversesTemplate;
