import { createSlice } from "@reduxjs/toolkit";

export const genderReducer = createSlice({
  name: "gender",
  initialState: {
    genders: [],
    gendersLoading: false,
    genderLoading: false,
  },
  reducers: {
    getGendersRequest: (state) => {
      state.gendersLoading = true;
    },
    getGendersSuccess: (state, action) => {
      state.genders = action.payload;
      state.gendersLoading = false;
    },
    getGendersFailed: (state) => {
      state.gendersLoading = false;
    },
    addGenderRequest: (state) => {
      state.genderLoading = true;
    },
    addGenderSuccess: (state) => {
      state.genderLoading = false;
    },
    addGenderFailed: (state) => {
      state.genderLoading = false;
    },
  },
});

export const {
  getGendersRequest,
  getGendersSuccess,
  getGendersFailed,
  addGenderRequest,
  addGenderSuccess,
  addGenderFailed,
} = genderReducer.actions;

export default genderReducer.reducer;
