import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addColor,
  getColor,
  updateColor,
} from "../../../redux/actions/colorActions";
import "./styles.css";

function ColorModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (toggleModal.category) {
      getColor(token, toggleModal.category)
        .then((res) => {
          let value = ""
          for(let i = 0; i < res.data.value.length;i++) {
            value = value + res.data.value[i]
            if(i < res.data.value.length -1) {
              value = value + ","
            }
          }
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            value: value,
            type: res.data.type,
            order: res.data.order,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddColor = (values) => {
    let value = []
    console.log(values.value)
    if(values.type ==="normal" ){
      value=[values.value]
    } else {
      const tempValues = values.value.split(",")
      for(let i = 0; i < tempValues.length;i++) {
        if(tempValues[i]) {value.push(tempValues[i].replaceAll(" ",""))}
      }
    }
    console.log(value)
    let body = {
      ...values,
      value
    }
    if (toggleModal.category) {
      updateColor(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addColor(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={toggleModal.category ? "Modifier le color" : "Ajouter un color"}
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddColor}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du color"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Select placeholder="1" style={{ width: "100%" }}>
            <Select.Option value="normal">Normal</Select.Option>
            <Select.Option value="gradient">Gradiant</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Valeur"
          name="value"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ColorModal;
