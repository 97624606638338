import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function TranslationsList(props) {
  const { editModal } = props;
  const { translations } = useSelector((state) => state.translationReducer);

  return (
    <div className="list_container">
      <div>
        <Row className="home_head_table">
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Clé de traduction</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Anglais</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Français</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="px5 py10 h-full">Action</div>
          </Col>
        </Row>
        {translations.map((translation) => (
          <div key={translation._id} className="home_body_table">
              <Row>
                <Col xs={12} xl={6} className="h-auto">
                  <div className="home_head_table_cell home_body_table_text_left border_bottom">
                    {translation.key}
                  </div>
                </Col>
                <Col xs={12} xl={6} className="h-auto">
                  <div className="home_head_table_cell home_body_table_text_right border_bottom">
                    {translation.en}
                  </div>
                </Col>
                <Col xs={8} xl={6} className="h-auto">
                  <div className="home_head_table_cell home_body_table_text_right border_bottom">
                    {translation.fr}
                  </div>
                </Col>
                <Col xs={24} xl={6} className="h-auto border_bottom">
                  <div className="p5 h-full">
                    <Button
                      className="w-full"
                      type="primary"
                      onClick={() => editModal(translation._id)}
                    >
                      Modifier
                    </Button>
                  </div>
                </Col>
              </Row>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TranslationsList;
