import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.css";

function GendersList(props) {
  const { editModal } = props;
  const {t} = useTranslation()
  const { genders } = useSelector((state) => state.genderReducer);
  const [activeGenders, setActiveGenders] = useState([]);

  useEffect(() => {
    let newGenders = [...genders];
    setActiveGenders(newGenders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genders]);

  return (
    <div>
      <div>
        <Row className="home_head_table">
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Ordre</div>
          </Col>
          <Col xl={7} className="h-auto">
            <div className="home_head_table_cell">Nom</div>
          </Col>
          <Col xl={7} className="h-auto">
            <div className="home_head_table_cell">Active</div>
          </Col>
          <Col xl={7} className="h-auto">
            <div className="home_head_table_cell">Action</div>
          </Col>
        </Row>
        {activeGenders.map((gender) => (
          <Row key={gender._id} className="home_body_table">
            <Col xs={2} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left text-center">
                {gender.order}
              </div>
            </Col>
            <Col xs={22} xl={7} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {t(gender.name)}
              </div>
            </Col>
            <Col xs={8} xl={7} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {gender.active ? (
                  <div className="active-item">Active</div>
                ) : (
                  <div className="inactive-item">Inactive</div>
                )}
              </div>
            </Col>
            <Col xs={24} xl={7} className="h-auto">
              <div className="p5 h-full">
                <Button
                  className="w-full"
                  type="primary"
                  onClick={() => editModal(gender._id)}
                >
                  Modifier
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default GendersList;
