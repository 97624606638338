import axios from "axios";
import { toast } from "react-toastify";
import { PIECE_TYPES_URL } from "../api/pieceTypeApi";
import {
  addPieceTypeFailed,
  addPieceTypeRequest,
  addPieceTypeSuccess,
  getPieceTypesFailed,
  getPieceTypesRequest,
  getPieceTypesSuccess,
} from "../reducers/pieceTypeReducer";
import { getTranslations } from "./translationActions";

export const getPieceTypes = (token, dispatch) => {
  dispatch(getPieceTypesRequest());
  axios
    .get(PIECE_TYPES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getPieceTypesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getPieceTypesFailed());
    });
};

export const getPieceType = (token, id) => {
  return axios.get(`${PIECE_TYPES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addPieceType = (token, body, closeModal, dispatch) => {
  dispatch(addPieceTypeRequest());
  axios
    .post(PIECE_TYPES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addPieceTypeSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getPieceTypes(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addPieceTypeFailed());
    });
};

export const updatePieceType = (token, id, body, closeModal, dispatch) => {
  dispatch(addPieceTypeRequest());
  axios
    .put(`${PIECE_TYPES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addPieceTypeSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getPieceTypes(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addPieceTypeFailed());
    });
};
