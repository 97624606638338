import { createSlice } from "@reduxjs/toolkit";

export const conditionReducer = createSlice({
  name: "condition",
  initialState: {
    conditions: [],
    conditionsLoading: false,
    conditionLoading: false,
  },
  reducers: {
    getConditionsRequest: (state) => {
      state.conditionsLoading = true;
    },
    getConditionsSuccess: (state, action) => {
      state.conditions = action.payload;
      state.conditionsLoading = false;
    },
    getConditionsFailed: (state) => {
      state.conditionsLoading = false;
    },
    addConditionRequest: (state) => {
      state.conditionLoading = true;
    },
    addConditionSuccess: (state) => {
      state.conditionLoading = false;
    },
    addConditionFailed: (state) => {
      state.conditionLoading = false;
    },
  },
});

export const {
  getConditionsRequest,
  getConditionsSuccess,
  getConditionsFailed,
  addConditionRequest,
  addConditionSuccess,
  addConditionFailed,
} = conditionReducer.actions;

export default conditionReducer.reducer;
