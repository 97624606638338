import axios from "axios";
import { toast } from "react-toastify";
import { SIZES_URL } from "../api/sizeApi";
import {
  addSizeFailed,
  addSizeRequest,
  addSizeSuccess,
  getSizesFailed,
  getSizesRequest,
  getSizesSuccess,
} from "../reducers/sizeReducer";
import { getTranslations } from "./translationActions";

export const getSizes = (token, dispatch) => {
  dispatch(getSizesRequest());
  axios
    .get(SIZES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getSizesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getSizesFailed());
    });
};

export const getSize = (token, id) => {
  return axios.get(`${SIZES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addSize = (token, body, closeModal, dispatch) => {
  dispatch(addSizeRequest());
  axios
    .post(SIZES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addSizeSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getSizes(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addSizeFailed());
    });
};

export const updateSize = (token, id, body, closeModal, dispatch) => {
  dispatch(addSizeRequest());
  axios
    .put(`${SIZES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addSizeSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getSizes(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addSizeFailed());
    });
};
