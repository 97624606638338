import { createSlice } from "@reduxjs/toolkit";

export const pieceTypeReducer = createSlice({
  name: "pieceType",
  initialState: {
    pieceTypes: [],
    pieceTypesLoading: false,
    pieceTypeLoading: false,
  },
  reducers: {
    getPieceTypesRequest: (state) => {
      state.pieceTypesLoading = true;
    },
    getPieceTypesSuccess: (state, action) => {
      state.pieceTypes = action.payload;
      state.pieceTypesLoading = false;
    },
    getPieceTypesFailed: (state) => {
      state.pieceTypesLoading = false;
    },
    addPieceTypeRequest: (state) => {
      state.pieceTypeLoading = true;
    },
    addPieceTypeSuccess: (state) => {
      state.pieceTypeLoading = false;
    },
    addPieceTypeFailed: (state) => {
      state.pieceTypeLoading = false;
    },
  },
});

export const {
  getPieceTypesRequest,
  getPieceTypesSuccess,
  getPieceTypesFailed,
  addPieceTypeRequest,
  addPieceTypeSuccess,
  addPieceTypeFailed,
} = pieceTypeReducer.actions;

export default pieceTypeReducer.reducer;
