import axios from "axios";
import { toast } from "react-toastify";
import {
  BLOG_ARTICLE_IMAGE_URL,
  BLOG_ARTICLES_PROGRAM_URL,
  BLOG_ARTICLES_URL,
} from "../api/blogArticleApi";
import {
  addBlogArticleFailed,
  addBlogArticleRequest,
  addBlogArticleSuccess,
  getBlogArticlesFailed,
  getBlogArticlesRequest,
  getBlogArticlesSuccess,
} from "../reducers/blogArticleReducer";

export const getBlogArticles = (token, dispatch) => {
  dispatch(getBlogArticlesRequest());
  axios
    .get(BLOG_ARTICLES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getBlogArticlesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getBlogArticlesFailed());
    });
};

export const getBlogArticle = (token, id) => {
  return axios.get(`${BLOG_ARTICLES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addBlogArticleImage = (token, body) => {
  return axios.post(BLOG_ARTICLE_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addBlogArticle = (token, body, closeModal, dispatch) => {
  dispatch(addBlogArticleRequest());
  axios
    .post(BLOG_ARTICLES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addBlogArticleSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getBlogArticles(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addBlogArticleFailed());
    });
};

export const updateBlogArticle = (token, id, body, closeModal, dispatch) => {
  dispatch(addBlogArticleRequest());
  axios
    .put(`${BLOG_ARTICLES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addBlogArticleSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getBlogArticles(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addBlogArticleFailed());
    });
};


export const addBlogArticleProgram = (token, body, closeModal, dispatch) => {
  dispatch(addBlogArticleRequest());
  axios
    .post(BLOG_ARTICLES_PROGRAM_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addBlogArticleSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getBlogArticles(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addBlogArticleFailed());
    });
};

export const updateBlogArticleProgram = (token, id, body, closeModal, dispatch) => {
  dispatch(addBlogArticleRequest());
  axios
    .put(`${BLOG_ARTICLES_PROGRAM_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addBlogArticleSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getBlogArticles(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addBlogArticleFailed());
    });
};
