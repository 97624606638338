import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Header = () => {
  return (
    <header>
      <div className="headerContainer">
        <div className="headerLogo">Logo</div>
        <nav className="nav">
          <Link to="/">Home</Link>
          <Link to="/shops">Boutiques</Link>
          <Link to="/services">Services</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/universes">Univers</Link>
          <Link to="/translations">Traductions</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
