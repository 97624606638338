import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSize,
  getSize,
  updateSize,
} from "../../../redux/actions/sizeActions";
import "./styles.css";

function SizeModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { universes } = useSelector((state) => state.universeReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [universesParent, setUniversesParent] = useState([]);

  useEffect(() => {
    if (toggleModal.category) {
      getSize(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            order: res.data.order,
            value: res.data.value,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
          setUniversesParent(res.data.universesParent)
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddUniverseParent = (universe) => {
    const index = universesParent.findIndex((item) => item === universe);
    if (index < 0) {
      setUniversesParent((prevState) =>
        [...prevState, universe].sort((a, b) => a.order - b.order)
      );
    } else {
      setUniversesParent((prevState) =>
        prevState
          .filter((item) => item !== universe)
          .sort((a, b) => a.order - b.order)
      );
    }
  };

  const onAddSize = (data) => {
    const body = {
      ...data,
      universesParent
    }
    if (toggleModal.category) {
      updateSize(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addSize(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le size" : "Ajouter un size"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddSize}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du size"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Valeur du size"
          name="value"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Univers parent"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          {universes.map((universe) => (
            <div
              style={{
                border: "1px solid lightgray",
                padding: 5,
                marginBottom: 5,
              }}
              onClick={() => onAddUniverseParent(universe._id)}
            >
              <Checkbox
                style={{ marginRight: 10 }}
                checked={universesParent.includes(universe._id)}
              />
              {universe.name}
            </div>
          ))}
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SizeModal;
