import axios from "axios";
import { toast } from "react-toastify";
import { EVENTS_URL } from "../api/eventApi";
import {
  addEventFailed,
  addEventRequest,
  addEventSuccess,
  getEventsFailed,
  getEventsRequest,
  getEventsSuccess,
} from "../reducers/eventReducer";
import { getTranslations } from "./translationActions";

export const getEvents = (token, dispatch) => {
  dispatch(getEventsRequest());
  axios
    .get(EVENTS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getEventsSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getEventsFailed());
    });
};

export const getEvent = (token, id) => {
  return axios.get(`${EVENTS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addEvent = (token, body, closeModal, dispatch) => {
  dispatch(addEventRequest());
  axios
    .post(EVENTS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addEventSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getEvents(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addEventFailed());
    });
};

export const updateEvent = (token, id, body, closeModal, dispatch) => {
  dispatch(addEventRequest());
  axios
    .put(`${EVENTS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addEventSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getEvents(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addEventFailed());
    });
};
