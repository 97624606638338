import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addShop,
  addShopImage,
  getShop,
  updateShop,
} from "../../../redux/actions/shopActions";
import "./styles.css";

const searchOptions = {
  componentRestrictions: {
    country: "fr",
  },
};

function ShopModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [shopImage, setShopImage] = useState(null);
  const [originValue, setOriginValue] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (toggleModal.category) {
      getShop(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            image: res.data.image,
            phone: res.data.phone,
            price: res.data.price,
            email: res.data.email,
            activeDelivery: res.data.activeDelivery,
            clickCollect: res.data.clickCollect,
            isPhysicalShop: res.data.isPhysicalShop,
            siret: res.data.siret,
            active: res.data.active,
          };
          setShopImage(res.data.image);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onChangeCategoryImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addShopImage(token, formData)
      .then((res) => {
        console.log(res.data);
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          image: res.data,
        };
        form.setFieldsValue(fields);
        setShopImage(res.data);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };

  const onSelectOrigin = async (e) => {
    const address = e.label;
    const destination = await geocodeByAddress(address);
    const data = destination[0].address_components;
    const destinationLatLng = await getLatLng(destination[0]);
    const locationTemp = {
      address1: "",
      city: "",
      region: "",
      zip: "",
      country: "",
      coordinates: [destinationLatLng.lng, destinationLatLng.lat],
    };

    data.forEach((component) => {
      if (component.types.includes("street_number")) {
        locationTemp.address1 += component.long_name + " ";
      }
      if (component.types.includes("route")) {
        locationTemp.address1 += component.long_name;
      }
      if (component.types.includes("locality")) {
        locationTemp.city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        locationTemp.region = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        locationTemp.zip = component.long_name;
      }
      if (component.types.includes("country")) {
        locationTemp.country = {
          name: component.long_name,
          short: component.short_name.toLocaleLowerCase(),
        };
      }
    });
    let fields = form.getFieldsValue();
    fields = {
      ...fields,
      address: locationTemp.address1,
    };
    form.setFieldsValue(fields);
    setLocation(locationTemp);
    setOriginValue(e);
  };

  const onAddShop = (values) => {
    const body = {
      ...values,
      location,
    };
    if (toggleModal.category) {
      updateShop(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addShop(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={toggleModal.category ? "Modifier le shop" : "Ajouter un shop"}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      width={1000}
    >
      <Form
        layout="vertical"
        onFinish={onAddShop}
        form={form}
        initialValues={{
          email: "",
          siret: "",
          name: "",
          address: "",
          phone: "",
          active: true,
          clickCollect: false,
          isPhysicalShop: true,
        }}
      >
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item
              label="Logo"
              name="image"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter l'image principale de la catégorie!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangeCategoryImage(e)}
                  type="file"
                  id="new_category_image"
                  name="new_category_image"
                />
                <label htmlFor="new_category_image">
                  {shopImage ? (
                    <img
                      src={shopImage}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
            <Form.Item
              label="Adresse Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Numéro de siret de la boutique"
              name="siret"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Nom de la boutique"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Adresse de la boutique"
              name="address"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              htmlFor="address"
            >
              <GooglePlacesAutocomplete
                id="address"
                debounce={500}
                minLengthAutocomplete={3}
                autocompletionRequest={searchOptions}
                selectProps={{
                  value: originValue,
                  onChange: onSelectOrigin,
                }}
              />
            </Form.Item>
            <Form.Item
              label="Numéro de la téléphone boutique"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label="Active"
              name="active"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Click&Collect"
              name="clickCollect"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Boutique physique"
              name="isPhysicalShop"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ShopModal;
