import { Button } from "antd";
import React from "react";
import "./styles.css";

export default function OpelModal(props) {
  const { title, openModal } = props;
  return (
    <div className="open-modal">
      <Button onClick={openModal} type="primary">
        {title}
      </Button>
    </div>
  );
}
