import axios from "axios";
import { toast } from "react-toastify";
import { PIECE_CATEGORIES_URL } from "../api/pieceCategory";
import {
  addPieceCategoryFailed,
  addPieceCategoryRequest,
  addPieceCategorySuccess,
  getPieceCategoriesFailed,
  getPieceCategoriesRequest,
  getPieceCategoriesSuccess,
} from "../reducers/pieceCategoryReducer";
import { getTranslations } from "./translationActions";

export const getPieceCategories = (token, dispatch) => {
  dispatch(getPieceCategoriesRequest());
  axios
    .get(PIECE_CATEGORIES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getPieceCategoriesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getPieceCategoriesFailed());
    });
};

export const getPieceCategory = (token, id) => {
  return axios.get(`${PIECE_CATEGORIES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addPieceCategory = (token, body, closeModal, dispatch) => {
  dispatch(addPieceCategoryRequest());
  axios
    .post(PIECE_CATEGORIES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addPieceCategorySuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getPieceCategories(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addPieceCategoryFailed());
    });
};

export const updatePieceCategory = (token, id, body, closeModal, dispatch) => {
  dispatch(addPieceCategoryRequest());
  axios
    .put(`${PIECE_CATEGORIES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addPieceCategorySuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getPieceCategories(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addPieceCategoryFailed());
    });
};
