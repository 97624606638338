import { createSlice } from "@reduxjs/toolkit";

export const categoryReducer = createSlice({
  name: "category",
  initialState: {
    categories: [],
    categoriesLoading: false,
    categoryLoading: false,
  },
  reducers: {
    getCategoriesRequest: (state) => {
      state.categoriesLoading = true;
    },
    getCategoriesSuccess: (state, action) => {
      state.categories = action.payload;
      state.categoriesLoading = false;
    },
    getCategoriesFailed: (state) => {
      state.categoriesLoading = false;
    },
    addCategoryRequest: (state) => {
      state.categoryLoading = true;
    },
    addCategorySuccess: (state) => {
      state.categoryLoading = false;
    },
    addCategoryFailed: (state) => {
      state.categoryLoading = false;
    },
  },
});

export const {
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFailed,
  addCategoryRequest,
  addCategorySuccess,
  addCategoryFailed,
} = categoryReducer.actions;

export default categoryReducer.reducer;
