import { Button, Col, Image, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.css";

function UniversesList(props) {
  const { t } = useTranslation();
  const { editModal } = props;
  const { universes } = useSelector((state) => state.universeReducer);
  const [activeUniverses, setActiveUniverses] = useState([]);

  useEffect(() => {
    let newUniverses = [...universes];
    setActiveUniverses(newUniverses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universes]);

  return (
    <div>
      <div>
        <Row className="home_head_table">
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Ordre</div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Image</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Nom</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Active</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Action</div>
          </Col>
        </Row>
        {activeUniverses.map((universe) => (
          <Row key={universe._id} className="home_body_table">
            <Col xs={2} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left text-center">
                {universe.order}
              </div>
            </Col>
            <Col xs={12} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                <Image src={universe.image} height={50} width={50}/>
              </div>
            </Col>
            <Col xs={22} xl={6} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {t(universe.name)}
              </div>
            </Col>
            <Col xs={8} xl={6} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {universe.active ? (
                  <div className="active-item">Active</div>
                ) : (
                  <div className="inactive-item">Inactive</div>
                )}
              </div>
            </Col>
            <Col xs={24} xl={6} className="h-auto">
              <div className="p5 h-full">
                <Button
                  className="w-full"
                  type="primary"
                  onClick={() => editModal(universe._id)}
                >
                  Modifier
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default UniversesList;
