import { Col, Row } from "antd";
import React from "react";
import {
  CategoriesTemplate,
  ColorsTemplate,
  ConditionsTemplate,
  EventsTemplate,
  GendersTemplate,
  PieceCategoriesTemplate,
  PiecesTemplate,
  PieceTypesTemplate,
  SizesTemplate,
  SubCategoriesTemplate,
  UniversesTemplate,
} from "../../../components/templates";

function Universes() {
  return (
    <div className="list_container">
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <UniversesTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <ConditionsTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <GendersTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <CategoriesTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <ColorsTemplate />
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <SubCategoriesTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <EventsTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <PiecesTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <SizesTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <PieceCategoriesTemplate />
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              padding: 10,
              marginTop: 20,
            }}
          >
            <PieceTypesTemplate />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Universes;
