import React, { useState } from "react";
import { ColorModal } from "../../mollecules";
import { ColorsList, OpelModal } from "../../organisms";

function ColorsTemplate() {
  const [toggleColorModal, setToggleColorModal] = useState({
    active: false,
    category: null,
  });

  const openColorModal = () => {
    setToggleColorModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeColorModal = () => {
    setToggleColorModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editColorModal = (id) => {
    setToggleColorModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleColorModal.active && (
        <ColorModal
          closeModal={closeColorModal}
          toggleModal={toggleColorModal}
        />
      )}
      <OpelModal title="Ajouter un color" openModal={openColorModal} />
      <ColorsList editModal={editColorModal} />
    </div>
  );
}

export default ColorsTemplate;
