import { createSlice } from "@reduxjs/toolkit";

export const colorReducer = createSlice({
  name: "color",
  initialState: {
    colors: [],
    colorsLoading: false,
    colorLoading: false,
  },
  reducers: {
    getColorsRequest: (state) => {
      state.colorsLoading = true;
    },
    getColorsSuccess: (state, action) => {
      state.colors = action.payload;
      state.colorsLoading = false;
    },
    getColorsFailed: (state) => {
      state.colorsLoading = false;
    },
    addColorRequest: (state) => {
      state.colorLoading = true;
    },
    addColorSuccess: (state) => {
      state.colorLoading = false;
    },
    addColorFailed: (state) => {
      state.colorLoading = false;
    },
  },
});

export const {
  getColorsRequest,
  getColorsSuccess,
  getColorsFailed,
  addColorRequest,
  addColorSuccess,
  addColorFailed,
} = colorReducer.actions;

export default colorReducer.reducer;
