import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/actions/adminActions";
import "./styles.css";

const Login = () => {
  const dispatch = useDispatch();
  const onFinish = (values) => {
    loginUser(values, dispatch);
  };

  return (
    <div className="login_container">
      <div className="login_form_container">
        <Form
          className="login_form"
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
