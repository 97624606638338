import React, { useState } from "react";
import { ConditionModal } from "../../mollecules";
import { ConditionsList, OpelModal } from "../../organisms";

function ConditionsTemplate() {
  const [toggleConditionModal, setToggleConditionModal] = useState({
    active: false,
    category: null,
  });

  const openConditionModal = () => {
    setToggleConditionModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeConditionModal = () => {
    setToggleConditionModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editConditionModal = (id) => {
    setToggleConditionModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleConditionModal.active && (
        <ConditionModal
          closeModal={closeConditionModal}
          toggleModal={toggleConditionModal}
        />
      )}
      <OpelModal title="Ajouter un condition" openModal={openConditionModal} />
      <ConditionsList editModal={editConditionModal} />
    </div>
  );
}

export default ConditionsTemplate;
