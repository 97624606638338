import { createSlice } from "@reduxjs/toolkit";

export const serviceReducer = createSlice({
  name: "service",
  initialState: {
    services: [],
    servicesLoading: false,
    serviceLoading: false,
  },
  reducers: {
    getServicesRequest: (state) => {
      state.servicesLoading = true;
    },
    getServicesSuccess: (state, action) => {
      state.services = action.payload;
      state.servicesLoading = false;
    },
    getServicesFailed: (state) => {
      state.servicesLoading = false;
    },
    addServiceRequest: (state) => {
      state.serviceLoading = true;
    },
    addServiceSuccess: (state) => {
      state.serviceLoading = false;
    },
    addServiceFailed: (state) => {
      state.serviceLoading = false;
    },
  },
});

export const {
  getServicesRequest,
  getServicesSuccess,
  getServicesFailed,
  addServiceRequest,
  addServiceSuccess,
  addServiceFailed,
} = serviceReducer.actions;

export default serviceReducer.reducer;
