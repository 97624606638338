import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.css";

function CategoriesList(props) {
  const { editModal } = props;
  const { t } = useTranslation();
  const { universes } = useSelector((state) => state.universeReducer);
  const { categories } = useSelector((state) => state.categoryReducer);
  const [activeCategories, setActiveCategories] = useState([]);

  useEffect(() => {
    let newCategories = [...categories];
    setActiveCategories(newCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <div>
      <div>
        <Row className="home_head_table">
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Ordre</div>
          </Col>
          <Col xl={7} className="h-auto">
            <div className="home_head_table_cell">Nom</div>
          </Col>
          <Col xl={5} className="h-auto">
            <div className="home_head_table_cell">Parents</div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Active</div>
          </Col>
          <Col xl={7} className="h-auto">
            <div className="home_head_table_cell">Action</div>
          </Col>
        </Row>
        {activeCategories.map((category) => (
          <Row key={category._id} className="home_body_table">
            <Col xs={2} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left text-center">
                {category.order}
              </div>
            </Col>
            <Col xs={22} xl={7} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {t(category.name)}
              </div>
            </Col>
            <Col xs={22} xl={5} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {category.universesParent.map((universe) => (
                  <div key={universe}>
                    {universes.find((item) => item._id === universe).name}
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={8} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {category.active ? (
                  <div className="active-item">Active</div>
                ) : (
                  <div className="inactive-item">Inactive</div>
                )}
              </div>
            </Col>
            <Col xs={24} xl={7} className="h-auto">
              <div className="p5 h-full">
                <Button
                  className="w-full"
                  type="primary"
                  onClick={() => editModal(category._id)}
                >
                  Modifier
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default CategoriesList;
