import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./reducers/adminReducer";
import blogArticleReducer from "./reducers/blogArticleReducer";
import categoryReducer from "./reducers/categoryReducer";
import colorReducer from "./reducers/colorReducer";
import conditionReducer from "./reducers/conditionReducer";
import eventReducer from "./reducers/eventReducer";
import genderReducer from "./reducers/genderReducer";
import pieceCategoryReducer from "./reducers/pieceCategoryReducer";
import pieceReducer from "./reducers/pieceReducer";
import pieceTypeReducer from "./reducers/pieceTypeReducer";
import serviceReducer from "./reducers/serviceReducer";
import shopReducer from "./reducers/shopReducer";
import sizeReducer from "./reducers/sizeReducer";
import subCategoryReducer from "./reducers/subCategoryReducer";
import translationReducer from "./reducers/translationReducer";
import universeReducer from "./reducers/universeReducer";

export default configureStore({
  reducer: {
    adminReducer: adminReducer,
    serviceReducer: serviceReducer,
    blogArticleReducer: blogArticleReducer,
    translationReducer: translationReducer,
    shopReducer: shopReducer,
    universeReducer: universeReducer,
    categoryReducer:categoryReducer,
    conditionReducer: conditionReducer,
    genderReducer: genderReducer,
    eventReducer:eventReducer,
    pieceReducer: pieceReducer,
    subCategoryReducer:subCategoryReducer,
    sizeReducer: sizeReducer,
    colorReducer: colorReducer,
    pieceCategoryReducer:pieceCategoryReducer,
    pieceTypeReducer:pieceTypeReducer
  },
});
