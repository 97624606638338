import axios from "axios";
import { toast } from "react-toastify";
import { PIECES_URL } from "../api/pieceApi";
import {
  addPieceFailed,
  addPieceRequest,
  addPieceSuccess,
  getPiecesFailed,
  getPiecesRequest,
  getPiecesSuccess,
} from "../reducers/pieceReducer";
import { getTranslations } from "./translationActions";

export const getPieces = (token, dispatch) => {
  dispatch(getPiecesRequest());
  axios
    .get(PIECES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getPiecesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getPiecesFailed());
    });
};

export const getPiece = (token, id) => {
  return axios.get(`${PIECES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addPiece = (token, body, closeModal, dispatch) => {
  dispatch(addPieceRequest());
  axios
    .post(PIECES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addPieceSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getPieces(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addPieceFailed());
    });
};

export const updatePiece = (token, id, body, closeModal, dispatch) => {
  dispatch(addPieceRequest());
  axios
    .put(`${PIECES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addPieceSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getPieces(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addPieceFailed());
    });
};
