import { createSlice } from "@reduxjs/toolkit";

export const eventReducer = createSlice({
  name: "event",
  initialState: {
    events: [],
    eventsLoading: false,
    eventLoading: false,
  },
  reducers: {
    getEventsRequest: (state) => {
      state.eventsLoading = true;
    },
    getEventsSuccess: (state, action) => {
      state.events = action.payload;
      state.eventsLoading = false;
    },
    getEventsFailed: (state) => {
      state.eventsLoading = false;
    },
    addEventRequest: (state) => {
      state.eventLoading = true;
    },
    addEventSuccess: (state) => {
      state.eventLoading = false;
    },
    addEventFailed: (state) => {
      state.eventLoading = false;
    },
  },
});

export const {
  getEventsRequest,
  getEventsSuccess,
  getEventsFailed,
  addEventRequest,
  addEventSuccess,
  addEventFailed,
} = eventReducer.actions;

export default eventReducer.reducer;
