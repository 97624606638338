import React, { useState } from "react";
import { GenderModal } from "../../mollecules";
import { GendersList, OpelModal } from "../../organisms";

function GendersTemplate() {
  const [toggleGenderModal, setToggleGenderModal] = useState({
    active: false,
    category: null,
  });

  const openGenderModal = () => {
    setToggleGenderModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeGenderModal = () => {
    setToggleGenderModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editGenderModal = (id) => {
    setToggleGenderModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleGenderModal.active && (
        <GenderModal
          closeModal={closeGenderModal}
          toggleModal={toggleGenderModal}
        />
      )}
      <OpelModal title="Ajouter un gender" openModal={openGenderModal} />
      <GendersList editModal={editGenderModal} />
    </div>
  );
}

export default GendersTemplate;
