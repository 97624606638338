import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.css";

function PieceTypesList(props) {
  const { editModal } = props;
  const {t} = useTranslation()
  const { pieceTypes } = useSelector((state) => state.pieceTypeReducer);
  const { pieceCategories } = useSelector((state) => state.pieceCategoryReducer);
  const [activePieceTypes, setActivePieceTypes] = useState([]);

  useEffect(() => {
    let newPieceTypes = [...pieceTypes];
    setActivePieceTypes(newPieceTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieceTypes]);

  return (
    <div>
      <div>
        <Row className="home_head_table">
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Ordre</div>
          </Col>
          <Col xl={6} className="h-auto">
            <div className="home_head_table_cell">Nom</div>
          </Col>
          <Col xl={5} className="h-auto">
            <div className="home_head_table_cell">Catégorie</div>
          </Col>
          <Col xl={4} className="h-auto">
            <div className="home_head_table_cell">Active</div>
          </Col>
          <Col xl={4} className="h-auto">
            <div className="home_head_table_cell">Action</div>
          </Col>
        </Row>
        {activePieceTypes.map((pieceType) => (
          <Row key={pieceType._id} className="home_body_table">
          <Col xs={2} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_left text-center">
              {pieceType.order}
            </div>
          </Col>
          <Col xs={22} xl={6} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
              {t(pieceType.name)}
            </div>
          </Col>
          <Col xs={22} xl={5} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {pieceType.pieceCategoriesParent.map((type) => (
                  <div key={type}>
                    {t(pieceCategories.find((item) => item._id === type).name)}
                  </div>
                ))}
            </div>
          </Col>
          <Col xs={8} xl={4} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_left">
              {pieceType.active ? (
                <div className="active-item">Active</div>
              ) : (
                <div className="inactive-item">Inactive</div>
              )}
            </div>
          </Col>
          <Col xs={24} xl={4} className="h-auto">
            <div className="p5 h-full">
              <Button
                className="w-full"
                type="primary"
                onClick={() => editModal(pieceType._id)}
              >
                Modifier
              </Button>
            </div>
          </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default PieceTypesList;
