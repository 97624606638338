import { createSlice } from "@reduxjs/toolkit";

export const subCategoryReducer = createSlice({
  name: "subCategory",
  initialState: {
    subCategories: [],
    subCategoriesLoading: false,
    subCategoryLoading: false,
  },
  reducers: {
    getSubCategoriesRequest: (state) => {
      state.subCategoriesLoading = true;
    },
    getSubCategoriesSuccess: (state, action) => {
      state.subCategories = action.payload;
      state.subCategoriesLoading = false;
    },
    getSubCategoriesFailed: (state) => {
      state.subCategoriesLoading = false;
    },
    addSubCategoryRequest: (state) => {
      state.subCategoryLoading = true;
    },
    addSubCategorySuccess: (state) => {
      state.subCategoryLoading = false;
    },
    addSubCategoryFailed: (state) => {
      state.subCategoryLoading = false;
    },
  },
});

export const {
  getSubCategoriesRequest,
  getSubCategoriesSuccess,
  getSubCategoriesFailed,
  addSubCategoryRequest,
  addSubCategorySuccess,
  addSubCategoryFailed,
} = subCategoryReducer.actions;

export default subCategoryReducer.reducer;
