import { createSlice } from "@reduxjs/toolkit";

export const adminReducer = createSlice({
  name: "admin",
  initialState: {
    token: null,
    admin: null,
    adminLoading: false,
  },
  reducers: {
    loginAdminRequest: (state) => {
      state.adminLoading = true;
    },
    loginAdminSuccess: (state, action) => {
      state.token = action.payload.token;
      state.admin = action.payload.admin;
      state.adminLoading = false;
      localStorage.setItem("token", action.payload.token);
    },
    loginAdminFailed: (state) => {
      state.adminLoading = false;
    },
  },
});

export const { loginAdminRequest, loginAdminSuccess, loginAdminFailed } =
  adminReducer.actions;

export default adminReducer.reducer;
