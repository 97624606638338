import axios from "axios";
import { toast } from "react-toastify";
import { TRANSLATIONS_URL } from "../api/translationApi";
import {
  addTranslationFailed,
  addTranslationRequest,
  addTranslationSuccess,
  getTranslationsFailed,
  getTranslationsRequest,
  getTranslationsSuccess,
} from "../reducers/translationReducer";

export const getTranslations = (token, dispatch) => {
  dispatch(getTranslationsRequest());
  axios
    .get(TRANSLATIONS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getTranslationsSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getTranslationsFailed());
    });
};

export const getTranslation = (token, id) => {
  return axios.get(`${TRANSLATIONS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addTranslation = (token, body, closeModal, dispatch) => {
  console.log(body);
  dispatch(addTranslationRequest());
  axios
    .post(TRANSLATIONS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addTranslationSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addTranslationFailed());
    });
};

export const updateTranslation = (token, id, body, closeModal, dispatch) => {
  dispatch(addTranslationRequest());
  axios
    .put(`${TRANSLATIONS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addTranslationSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addTranslationFailed());
    });
};
