import { createSlice } from "@reduxjs/toolkit";

export const translationReducer = createSlice({
  name: "translation",
  initialState: {
    translations: [],
    translationCategories: [],
    translationsLoading: false,
    translationLoading: false,
  },
  reducers: {
    getTranslationsRequest: (state) => {
      state.translationsLoading = true;
    },
    getTranslationsSuccess: (state, action) => {
      state.translations = action.payload;
      state.translationsLoading = false;
    },
    getTranslationsFailed: (state) => {
      state.translationsLoading = false;
    },
    addTranslationRequest: (state) => {
      state.translationLoading = true;
    },
    addTranslationSuccess: (state) => {
      state.translationLoading = false;
    },
    addTranslationFailed: (state) => {
      state.translationLoading = false;
    },
  },
});

export const {
  getTranslationsRequest,
  getTranslationsSuccess,
  getTranslationsFailed,
  addTranslationRequest,
  addTranslationSuccess,
  addTranslationFailed,
} = translationReducer.actions;

export default translationReducer.reducer;
