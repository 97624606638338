import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function ShopsList(props) {
  const { editModal } = props;
  const { shops } = useSelector((state) => state.shopReducer);
  const [activeShops, setActiveShops] = useState([]);

  useEffect(() => {
    let newShops = [...shops];
    setActiveShops(newShops);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shops]);

  return (
    <div>
      <div>
        <Row className="home_head_table">
          <Col xl={1} className="h-auto">
            <div className="home_head_table_cell">Ordre</div>
          </Col>
          <Col xl={5} className="h-auto">
            <div className="home_head_table_cell">Nom</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Prix</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Durée</div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Catégorie</div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Sous-catégorie</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Active</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Réservable</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Option</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="px5 py10 h-full">Action</div>
          </Col>
        </Row>
        {activeShops.map((shop) => (
          <Row key={shop._id} className="home_body_table">
            <Col xs={2} xl={1} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left text-center">
                {shop.order}
              </div>
            </Col>
            <Col xs={22} xl={5} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {shop.name}
              </div>
            </Col>
            <Col xs={12} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {shop.price}€
              </div>
            </Col>
            <Col xs={12} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right">
                {shop.duration} minutes
              </div>
            </Col>
            <Col xs={12} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right">
                {shops.findIndex(
                  (item) => item._id === shop.subCategory
                ) >= 0
                  ? shops[
                      shops.findIndex(
                        (item) => item._id === shop.subCategory
                      )
                    ].name
                  : ""}
              </div>
            </Col>
            <Col xs={8} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {shop.active ? (
                  <div className="active-item">Active</div>
                ) : (
                  <div className="inactive-item">Inactive</div>
                )}
              </div>
            </Col>
            <Col xs={8} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {shop.isReservable ? (
                  <div className="active-item">Réservable</div>
                ) : (
                  <div className="inactive-item">Non réservable</div>
                )}
              </div>
            </Col>
            <Col xs={8} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right">
                {shop.isOption ? (
                  <div className="active-item">Option</div>
                ) : (
                  <div></div>
                )}
              </div>
            </Col>
            <Col xs={24} xl={2} className="h-auto">
              <div className="p5 h-full">
                <Button
                  className="w-full"
                  type="primary"
                  onClick={() => editModal(shop._id)}
                >
                  Modifier
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default ShopsList;
