import axios from "axios";
import { toast } from "react-toastify";
import { GENDERS_URL } from "../api/genderApi";
import {
  addGenderFailed,
  addGenderRequest,
  addGenderSuccess,
  getGendersFailed,
  getGendersRequest,
  getGendersSuccess,
} from "../reducers/genderReducer";
import { getTranslations } from "./translationActions";

export const getGenders = (token, dispatch) => {
  dispatch(getGendersRequest());
  axios
    .get(GENDERS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getGendersSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getGendersFailed());
    });
};

export const getGender = (token, id) => {
  return axios.get(`${GENDERS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addGender = (token, body, closeModal, dispatch) => {
  dispatch(addGenderRequest());
  axios
    .post(GENDERS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addGenderSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getGenders(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addGenderFailed());
    });
};

export const updateGender = (token, id, body, closeModal, dispatch) => {
  dispatch(addGenderRequest());
  axios
    .put(`${GENDERS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addGenderSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getGenders(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addGenderFailed());
    });
};
