import React, { useState } from "react";
import { OpelModal, ServicesList } from "../../organisms";
import { ServiceModal } from "../../mollecules";

function ServicesTemplate() {
  const [toggleServiceModal, setToggleServiceModal] = useState({
    active: false,
    category: null,
  });

  const openServiceModal = () => {
    setToggleServiceModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeServiceModal = () => {
    setToggleServiceModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editServiceModal = (id) => {
    setToggleServiceModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleServiceModal.active && (
        <ServiceModal
          closeModal={closeServiceModal}
          toggleModal={toggleServiceModal}
        />
      )}
      <OpelModal title="Ajouter un service" openModal={openServiceModal} />
      <ServicesList editModal={editServiceModal} />
    </div>
  );
}

export default ServicesTemplate;
