import React, { useState } from "react";
import { PieceCategoryModal } from "../../mollecules";
import { OpelModal, PieceCategoriesList } from "../../organisms";

function PieceCategoriesTemplate() {
  const [togglePieceCategoryModal, setTogglePieceCategoryModal] = useState({
    active: false,
    category: null,
  });

  const openPieceCategoryModal = () => {
    setTogglePieceCategoryModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closePieceCategoryModal = () => {
    setTogglePieceCategoryModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editPieceCategoryModal = (id) => {
    setTogglePieceCategoryModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {togglePieceCategoryModal.active && (
        <PieceCategoryModal
          closeModal={closePieceCategoryModal}
          toggleModal={togglePieceCategoryModal}
        />
      )}
      <OpelModal title="Ajouter un pieceCategory" openModal={openPieceCategoryModal} />
      <PieceCategoriesList editModal={editPieceCategoryModal} />
    </div>
  );
}

export default PieceCategoriesTemplate;
