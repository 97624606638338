import React, { useState } from "react";
import { ShopModal } from "../../mollecules";
import { OpelModal, ShopsList } from "../../organisms";

function ShopsTemplate() {
  const [toggleShopModal, setToggleShopModal] = useState({
    active: false,
    category: null,
  });

  const openShopModal = () => {
    setToggleShopModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeShopModal = () => {
    setToggleShopModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editShopModal = (id) => {
    setToggleShopModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleShopModal.active && (
        <ShopModal
          closeModal={closeShopModal}
          toggleModal={toggleShopModal}
        />
      )}
      <OpelModal title="Ajouter un shop" openModal={openShopModal} />
      <ShopsList editModal={editShopModal} />
    </div>
  );
}

export default ShopsTemplate;
