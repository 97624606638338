import axios from "axios";
import { toast } from "react-toastify";
import { SUBCATEGORIES_URL } from "../api/subCategoryApi";
import {
  addSubCategoryFailed,
  addSubCategoryRequest,
  addSubCategorySuccess,
  getSubCategoriesFailed,
  getSubCategoriesRequest,
  getSubCategoriesSuccess,
} from "../reducers/subCategoryReducer";
import { getTranslations } from "./translationActions";

export const getSubCategories = (token, dispatch) => {
  dispatch(getSubCategoriesRequest());
  axios
    .get(SUBCATEGORIES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getSubCategoriesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getSubCategoriesFailed());
    });
};

export const getSubCategory = (token, id) => {
  return axios.get(`${SUBCATEGORIES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addSubCategory = (token, body, closeModal, dispatch) => {
  dispatch(addSubCategoryRequest());
  axios
    .post(SUBCATEGORIES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addSubCategorySuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getSubCategories(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addSubCategoryFailed());
    });
};

export const updateSubCategory = (token, id, body, closeModal, dispatch) => {
  dispatch(addSubCategoryRequest());
  axios
    .put(`${SUBCATEGORIES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addSubCategorySuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getSubCategories(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addSubCategoryFailed());
    });
};
