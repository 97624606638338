import React, { useState } from "react";
import { TranslationModal } from "../../mollecules";
import { OpelModal, TranslationsList } from "../../organisms";

function TranslationsTemplate() {
  const [toggleTranslationModal, setToggleTranslationModal] = useState({
    active: false,
    category: null,
  });

  const openTranslationModal = () => {
    setToggleTranslationModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeTranslationModal = () => {
    setToggleTranslationModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editTranslationModal = (id) => {
    setToggleTranslationModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleTranslationModal.active && (
        <TranslationModal
          closeModal={closeTranslationModal}
          toggleModal={toggleTranslationModal}
        />
      )}
      <OpelModal
        title="Ajouter un translation"
        openModal={openTranslationModal}
      />
      <TranslationsList editModal={editTranslationModal} />
    </div>
  );
}

export default TranslationsTemplate;
