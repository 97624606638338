import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addUniverse,
  addUniverseImage,
  getUniverse,
  updateUniverse,
} from "../../../redux/actions/universeActions";
import "./styles.css";

function UniverseModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const {t}= useTranslation()
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [universeImage, setUniverseImage] = useState(null);

  useEffect(() => {
    if (toggleModal.category) {
      getUniverse(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: t(res.data.name),
            order: res.data.order,
            active: res.data.active,
            image: res.data.image
          };
          setUniverseImage(res.data.image)
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onChangeCategoryImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addUniverseImage(token, formData)
      .then((res) => {
        console.log(res.data);
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          image: res.data,
        };
        form.setFieldsValue(fields);
        setUniverseImage(res.data);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };

  const onAddUniverse = (body) => {
    if (toggleModal.category) {
      updateUniverse(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addUniverse(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le universe" : "Ajouter un universe"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddUniverse}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Photo"
          name="image"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter l'image principale de la catégorie!",
            },
          ]}
        >
          <div>
            <input
              className="product_form_input_none"
              onChange={(e) => onChangeCategoryImage(e)}
              type="file"
              id="new_category_image"
              name="new_category_image"
            />
            <label htmlFor="new_category_image">
              {universeImage ? (
                <img
                  src={universeImage}
                  alt="preview logo"
                  className="product_form_image"
                />
              ) : (
                <div className="product_form_image">+</div>
              )}
            </label>
          </div>
        </Form.Item>
        <Form.Item
          label="Nom du universe"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UniverseModal;
