import axios from "axios";
import { toast } from "react-toastify";
import { UNIVERSE_IMAGE_URL, UNIVERSES_URL } from "../api/universeApi";
import {
  addUniverseFailed,
  addUniverseRequest,
  addUniverseSuccess,
  getUniversesFailed,
  getUniversesRequest,
  getUniversesSuccess,
} from "../reducers/universeReducer";
import { getTranslations } from "./translationActions";

export const getUniverses = (token, dispatch) => {
  dispatch(getUniversesRequest());
  axios
    .get(UNIVERSES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(getUniversesSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getUniversesFailed());
    });
};

export const getUniverse = (token, id) => {
  return axios.get(`${UNIVERSES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addUniverseImage = (token, body) => {
  return axios.post(UNIVERSE_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addUniverse = (token, body, closeModal, dispatch) => {
  dispatch(addUniverseRequest());
  axios
    .post(UNIVERSES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addUniverseSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getUniverses(token, dispatch);
      getTranslations(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addUniverseFailed());
    });
};

export const updateUniverse = (token, id, body, closeModal, dispatch) => {
  dispatch(addUniverseRequest());
  axios
    .put(`${UNIVERSES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addUniverseSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getUniverses(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addUniverseFailed());
    });
};
