import React, { useState } from "react";
import { PieceModal } from "../../mollecules";
import { OpelModal, PiecesList } from "../../organisms";

function PiecesTemplate() {
  const [togglePieceModal, setTogglePieceModal] = useState({
    active: false,
    category: null,
  });

  const openPieceModal = () => {
    setTogglePieceModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closePieceModal = () => {
    setTogglePieceModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editPieceModal = (id) => {
    setTogglePieceModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {togglePieceModal.active && (
        <PieceModal
          closeModal={closePieceModal}
          toggleModal={togglePieceModal}
        />
      )}
      <OpelModal title="Ajouter un piece" openModal={openPieceModal} />
      <PiecesList editModal={editPieceModal} />
    </div>
  );
}

export default PiecesTemplate;
