import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Modal, Switch, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addPieceType,
  getPieceType,
  updatePieceType,
} from "../../../redux/actions/pieceTypeActions";
import "./styles.css";

function PieceTypeModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const {pieceCategories} = useSelector((state) => state.pieceCategoryReducer);
  const {t} = useTranslation()
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pieceCategoriesParent, setPieceCategoriesParent] = useState([]);

  useEffect(() => {
    if (toggleModal.category) {
      getPieceType(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: t(res.data.name),
            order: res.data.order,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddPieceCategoryParent = (pieceCategory) => {
    const index = pieceCategoriesParent.findIndex((item) => item === pieceCategory);
    if (index < 0) {
      setPieceCategoriesParent((prevState) =>
        [...prevState, pieceCategory].sort((a, b) => a.order - b.order)
      );
    } else {
      setPieceCategoriesParent((prevState) =>
        prevState
          .filter((item) => item !== pieceCategory)
          .sort((a, b) => a.order - b.order)
      );
    }
  };

  const onAddPieceType = (data) => {
    const body = {
      ...data,
      pieceCategoriesParent
    }
    if (toggleModal.category) {
      updatePieceType(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addPieceType(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le pieceType" : "Ajouter un pieceType"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddPieceType}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du pieceType"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Univers parent"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          {pieceCategories.map((pieceCategory) => (
            <div
              style={{
                border: "1px solid lightgray",
                padding: 5,
                marginBottom: 5,
              }}
              onClick={() => onAddPieceCategoryParent(pieceCategory._id)}
            >
              <Checkbox
                style={{ marginRight: 10 }}
                checked={pieceCategoriesParent.includes(pieceCategory._id)}
              />
              {t(pieceCategory.name)}
            </div>
          ))}
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PieceTypeModal;
