import { createSlice } from "@reduxjs/toolkit";

export const universeReducer = createSlice({
  name: "universe",
  initialState: {
    universes: [],
    universesLoading: false,
    universeLoading: false,
  },
  reducers: {
    getUniversesRequest: (state) => {
      state.universesLoading = true;
    },
    getUniversesSuccess: (state, action) => {
      state.universes = action.payload;
      state.universesLoading = false;
    },
    getUniversesFailed: (state) => {
      state.universesLoading = false;
    },
    addUniverseRequest: (state) => {
      state.universeLoading = true;
    },
    addUniverseSuccess: (state) => {
      state.universeLoading = false;
    },
    addUniverseFailed: (state) => {
      state.universeLoading = false;
    },
  },
});

export const {
  getUniversesRequest,
  getUniversesSuccess,
  getUniversesFailed,
  addUniverseRequest,
  addUniverseSuccess,
  addUniverseFailed,
} = universeReducer.actions;

export default universeReducer.reducer;
