import { createSlice } from "@reduxjs/toolkit";

export const blogArticleReducer = createSlice({
  name: "blogArticle",
  initialState: {
    blogArticles: [],
    blogArticlesLoading: false,
    blogArticleLoading: false,
  },
  reducers: {
    getBlogArticlesRequest: (state) => {
      state.blogArticlesLoading = true;
    },
    getBlogArticlesSuccess: (state, action) => {
      state.blogArticles = action.payload;
      state.blogArticlesLoading = false;
    },
    getBlogArticlesFailed: (state) => {
      state.blogArticlesLoading = false;
    },
    addBlogArticleRequest: (state) => {
      state.blogArticleLoading = true;
    },
    addBlogArticleSuccess: (state) => {
      state.blogArticleLoading = false;
    },
    addBlogArticleFailed: (state) => {
      state.blogArticleLoading = false;
    },
  },
});

export const {
  getBlogArticlesRequest,
  getBlogArticlesSuccess,
  getBlogArticlesFailed,
  addBlogArticleRequest,
  addBlogArticleSuccess,
  addBlogArticleFailed,
} = blogArticleReducer.actions;

export default blogArticleReducer.reducer;
