import axios from "axios";
import { toast } from "react-toastify";
import { SHOP_IMAGE_URL, SHOPS_URL } from "../api/shopApi";
import {
  addShopFailed,
  addShopRequest,
  addShopSuccess,
  getShopsFailed,
  getShopsRequest,
  getShopsSuccess,
} from "../reducers/shopReducer";

export const getShops = (token, dispatch) => {
  dispatch(getShopsRequest());
  axios
    .get(SHOPS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(getShopsSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(getShopsFailed());
    });
};

export const getShop = (token, id) => {
  return axios.get(`${SHOPS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addShopImage = (token, body) => {
  return axios.post(SHOP_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addShop = (token, body, closeModal, dispatch) => {
  console.log(body);
  dispatch(addShopRequest());
  axios
    .post(SHOPS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addShopSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getShops(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addShopFailed());
    });
};

export const updateShop = (token, id, body, closeModal, dispatch) => {
  dispatch(addShopRequest());
  axios
    .put(`${SHOPS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addShopSuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getShops(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addShopFailed());
    });
};
