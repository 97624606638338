import React, { useState } from "react";
import { PieceTypeModal } from "../../mollecules";
import { OpelModal, PieceTypesList } from "../../organisms";

function PieceTypesTemplate() {
  const [togglePieceTypeModal, setTogglePieceTypeModal] = useState({
    active: false,
    category: null,
  });

  const openPieceTypeModal = () => {
    setTogglePieceTypeModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closePieceTypeModal = () => {
    setTogglePieceTypeModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editPieceTypeModal = (id) => {
    setTogglePieceTypeModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {togglePieceTypeModal.active && (
        <PieceTypeModal
          closeModal={closePieceTypeModal}
          toggleModal={togglePieceTypeModal}
        />
      )}
      <OpelModal title="Ajouter un pieceType" openModal={openPieceTypeModal} />
      <PieceTypesList editModal={editPieceTypeModal} />
    </div>
  );
}

export default PieceTypesTemplate;
