import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addBlogArticle,
  addBlogArticleImage,
  addBlogArticleProgram,
  getBlogArticle,
  updateBlogArticle,
  updateBlogArticleProgram
} from "../../../redux/actions/blogArticleActions";
import "./styles.css";

function BlogArticleModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);

  useEffect(() => {
    if (toggleModal.category) {
      getBlogArticle(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            order: res.data.order,
            duration: res.data.duration,
            price: res.data.price,
            category: res.data.category,
            subCategory: res.data.subCategory,
            active: res.data.active,
            isReservable: res.data.isReservable,
            isOption: res.data.isOption,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onChangeImage = (data) => {
    console.log("onChangeImage");
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addBlogArticleImage(token, formData)
      .then((res) => {
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          image: res.data,
        };
        form.setFieldsValue(fields);
        setImage(res.data);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };

  const onChangeImage2 = (data) => {
    console.log("onChangeImage2");
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addBlogArticleImage(token, formData)
      .then((res) => {
        let fields = form.getFieldsValue();
        fields = {
          ...fields,
          image2: res.data,
        };
        form.setFieldsValue(fields);
        setImage2(res.data);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };

  const onAddBlogArticle = (body) => {
    if(body.date) {if (toggleModal.category) {
      updateBlogArticleProgram(
        token,
        toggleModal.category,
        body,
        closeModal,
        dispatch
      );
    } else {
      addBlogArticleProgram(token, body, closeModal, dispatch);
    }
    } else {
      if (toggleModal.category) {
        updateBlogArticle(
          token,
          toggleModal.category,
          body,
          closeModal,
          dispatch
        );
      } else {
        addBlogArticle(token, body, closeModal, dispatch);
      }
    }
  };

  return (
    <Modal
      title={
        toggleModal.category
          ? "Modifier le blogArticle"
          : "Ajouter un blogArticle"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      width={1000}
    >
      <Form
        layout="vertical"
        onFinish={onAddBlogArticle}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Photo"
              name="image"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter l'image principale de la catégorie!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangeImage(e)}
                  type="file"
                  id="new_category_image"
                  name="new_category_image"
                />
                <label htmlFor="new_category_image">
                  {image ? (
                    <img
                      src={image}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
            <Form.Item
              label="Titre le l'article"
              name="title"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Sous titre 1"
              name="subtitle1"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="text 1"
              name="content1"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Sous titre 2"
              name="subtitle2"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="text 2"
              name="content2"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Photo 2"
              name="image2"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter l'image principale de la catégorie!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangeImage2(e)}
                  type="file"
                  id="new_category_image2"
                  name="new_category_image2"
                />
                <label htmlFor="new_category_image2">
                  {image2 ? (
                    <img
                      src={image2}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
            <Form.Item
              label="Sous titre 3"
              name="subtitle3"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="text 3"
              name="content3"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Ordre d'affichage"
              name="order"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <InputNumber placeholder="1" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Active"
              name="active"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Programmer le post" name="date">
              <DatePicker style={{ width: "100%" }} picker="datetime"/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default BlogArticleModal;
