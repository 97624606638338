import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubCategory,
  getSubCategory,
  updateSubCategory,
} from "../../../redux/actions/subCategoryActions";
import "./styles.css";

function SubCategoryModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { categories } = useSelector((state) => state.categoryReducer);
  const { genders } = useSelector((state) => state.genderReducer);
  const {t} = useTranslation()
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [categoriesParent, setCategoriesParent] = useState([]);
  const [gendersParent, setGendersParent] = useState([]);

  useEffect(() => {
    if (toggleModal.category) {
      getSubCategory(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: t(res.data.name),
            order: res.data.order,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
          setCategoriesParent(res.data.categoriesParent)
          setGendersParent(res.data.gendersParent)
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddGenderParent = (gender) => {
    const index = gendersParent.findIndex((item) => item === gender);
    if (index < 0) {
      setGendersParent((prevState) =>
        [...prevState, gender].sort((a, b) => a.order - b.order)
      );
    } else {
      setGendersParent((prevState) =>
        prevState
          .filter((item) => item !== gender)
          .sort((a, b) => a.order - b.order)
      );
    }
  };

  const onAddCategoryParent = (category) => {
    const index = categoriesParent.findIndex((item) => item === category);
    if (index < 0) {
      setCategoriesParent((prevState) =>
        [...prevState, category].sort((a, b) => a.order - b.order)
      );
    } else {
      setCategoriesParent((prevState) =>
        prevState
          .filter((item) => item !== category)
          .sort((a, b) => a.order - b.order)
      );
    }
  };

  const onAddSubCategory = (data) => {
    const body = {
      ...data,
      categoriesParent,
      gendersParent
    }
    if (toggleModal.category) {
      updateSubCategory(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addSubCategory(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le subCategory" : "Ajouter un subCategory"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddSubCategory}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du subCategory"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Type de client parent"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          {genders.map((gender) => (
            <div
              style={{
                border: "1px solid lightgray",
                padding: 5,
                marginBottom: 5,
              }}
              onClick={() => onAddGenderParent(gender._id)}
            >
              <Checkbox
                style={{ marginRight: 10 }}
                checked={gendersParent.includes(gender._id)}
              />
              {t(gender.name)}
            </div>
          ))}
        </Form.Item>
        <Form.Item
          label="Catégorie parent"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
        {categories.map((category) => (
          <div
            style={{
              border: "1px solid lightgray",
              padding: 5,
              marginBottom: 5,
            }}
            onClick={() => onAddCategoryParent(category._id)}
          >
            <Checkbox
              style={{ marginRight: 10 }}
              checked={categoriesParent.includes(category._id)}
            />
            {t(category.name)}
          </div>
        ))}
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SubCategoryModal;
