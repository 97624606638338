import { createSlice } from "@reduxjs/toolkit";

export const shopReducer = createSlice({
  name: "shop",
  initialState: {
    shops: [],
    shopsLoading: false,
    shopLoading: false,
  },
  reducers: {
    getShopsRequest: (state) => {
      state.shopsLoading = true;
    },
    getShopsSuccess: (state, action) => {
      state.shops = action.payload;
      state.shopsLoading = false;
    },
    getShopsFailed: (state) => {
      state.shopsLoading = false;
    },
    addShopRequest: (state) => {
      state.shopLoading = true;
    },
    addShopSuccess: (state) => {
      state.shopLoading = false;
    },
    addShopFailed: (state) => {
      state.shopLoading = false;
    },
  },
});

export const {
  getShopsRequest,
  getShopsSuccess,
  getShopsFailed,
  addShopRequest,
  addShopSuccess,
  addShopFailed,
} = shopReducer.actions;

export default shopReducer.reducer;
